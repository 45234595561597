import React from 'react';

function Contact() {
    return (
        <div className='contact'>
            <h1 className="title text-4xl font-bold font-bold pb-8 text-slate-700">
                Contact
            </h1>
            <article className='p-2 text-wrap'>
                <div className="text-slate-600">
                    {/* <h2 className="font-bold">
                        Don't hesitate
                    </h2> */}
                    <p className='article text-1xl'>Hi there,<br /> <br />
                        Welcome to my world ! <br />

                        If you have any questions about my work or something else, don't hesitate to use this mail to contact me : <br />

                        <strong className='font-bold text-slate-600'><br />
                            Kynothancontact@gmail.com<br /><br />
                        </strong>

                        Same if you would like to chat about commissions or collaborations.<br />

                        To the pleasure of talking with you,<br />

                        Kynothan.
                    </p>
                </div>
            </article>
        </div >
    );
}

export default Contact;
