import React, { Component } from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import '../Styles/Pages/Layout.scss';

function Layout() {
    return (
        <div className="page-container">
            <header>
                <Header />
            </header>
            <div className="body-container">
                <Outlet />
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
}

export default Layout;
