import React from 'react';

interface CardProps {
    link: string;
    text: string;
    imageSrc: string;
}

const Card: React.FC<CardProps> = ({ link, text, imageSrc }) => {
    return (
        <a href={link} className="card bg-slate-300 flex items-center justify-center rounded-lg">
            <div className="">
                <img src={imageSrc} alt={text} className="cardpicture" />
                <p className="cardtext">{text}</p>
            </div>
        </a>
    );
};

export default Card;