import React from 'react';
import Card from '../Components/Card';

function Media() {
    return (
        <div className="media">
            <div className="pt-14 grid place-items-center">
                <div className='pb-4'>
                    <img className="mx-auto rounded-full w-24 h-24" src="./Kynothan512.png" alt="logo" />
                </div>
                <h1 className="font-bold text-slate-700">@Kynothan</h1>
            </div>
            <div className="pt-4 grid place-items-center gap-3">
                <Card
                    link="https://www.instagram.com/Kynothan/"
                    text="Instagram"
                    imageSrc="./img/instagram.svg"
                />
                <Card
                    link="https://www.tiktok.com/@kynothan"
                    text="TikTok"
                    imageSrc="./img/tiktok.svg"
                />
                <Card
                    link="https://twitter.com/Kynothan"
                    text="X"
                    imageSrc="./img/x.svg"
                />
                <Card
                    link="https://www.facebook.com/kynothan34"
                    text="Facebook"
                    imageSrc="./img/facebook.svg"
                />
                <Card
                    link="https://www.youtube.com/@kynothan"
                    text="Youtube"
                    imageSrc="./img/youtube.svg"
                />
                <Card
                    link="https://soundcloud.com/kynothan"
                    text="Soundcloud"
                    imageSrc="./img/soundcloud.svg"
                />
                <Card
                    link="https://open.spotify.com/artist/3n6Rbc3WXl82wNeUpoBqrV"
                    text="Spotify"
                    imageSrc="./img/spotify.svg"
                />
                <Card
                    link="https://threads.net/@Kynothan"
                    text="Threads"
                    imageSrc="./img/threads.svg"
                />
            </div>
        </div>
    );
}

export default Media;
