import React from 'react';
import { Link } from 'react-router-dom';

function NoMatch() {
    return (
        <div className="nomatch">
            <h2 className="text-3xl font-bold pb-8 text-slate-700">What are you looking for here ?</h2>
            <div className='pb-8'>
                <img className="mx-auto rounded-lg w-24 h-24" src="./Sky_00.gif" alt="CloudLoop" />
            </div>
            <Link to="/" className="text-slate-500 underline underline-offset-2 decoration-slate-700 hover:text-indigo-400">Go to the home page</Link>
        </div >
    );
}

export default NoMatch;
