import React from 'react';
import { Outlet, Link } from "react-router-dom";

function Header() {
    return (
        <div className="text-slate-500 flex justify-center gap-1">
            <div className="headerlabel">
                <Link to="/about">
                    <button className="hover:text-indigo-400">
                        About
                    </button>
                </Link>
            </div>
            <div className='headerlabel'>
                <Link to="/">
                    <button className="hover:text-indigo-400">
                        Social media
                    </button>
                </Link>
            </div>
            <div className='headerlabel'>
                <Link to="/contact">
                    <button className="hover:text-indigo-400">
                        Contact
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default Header;
