import React from 'react';

function About() {
    return (
        <div className="about">
            <h1 className="title text-4xl font-bold font-bold pb-8 text-slate-700">
                :)
            </h1>
        </div >
    );
}

export default About;
