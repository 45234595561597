import React from 'react';
import { Routes, Route } from "react-router-dom";
import Layout from './Pages/Layout';
import Media from './Pages/Media';
import About from './Pages/About';
import Contact from './Pages/Contact';
import NoMatch from './Pages/NoMatch';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Media />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div >
  );
}

export default App;
